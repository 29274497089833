import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import ComponentWow from "../../Wow";

const AccordionFAQ = () => {
  const { t } = useTranslation();

  return (
    <ComponentWow>
      <div className="container py-3">
        <div className="row">
          <div className="col-10 mx-auto">
            <div className="accordion" id="faqExample">
              <div className="card">
                <div className="card-header p-2" id="headingOne">
                  <h5 className="mb-0">
                    <button
                      className="btn btn-link"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      {t("faq.question")}: {t("faq.1.q")}
                    </button>
                  </h5>
                </div>

                <div
                  id="collapseOne"
                  className="collapse show"
                  aria-labelledby="headingOne"
                  data-parent="#faqExample"
                >
                  <div className="card-body">
                    <b>{t("faq.answer")}:</b> {t("faq.1.a")}
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header p-2" id="headingTwo">
                  <h5 className="mb-0">
                    <button
                      className="btn btn-link collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      {t("faq.question")}: {t("faq.2.q")}
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseTwo"
                  className="collapse"
                  aria-labelledby="headingTwo"
                  data-parent="#faqExample"
                >
                  <div className="card-body">
                    <b>{t("faq.answer")}:</b> {t("faq.1.a")}
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header p-2" id="headingThree">
                  <h5 className="mb-0">
                    <button
                      className="btn btn-link collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      {t("faq.question")}: {t("faq.3.q")}
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseThree"
                  className="collapse"
                  aria-labelledby="headingThree"
                  data-parent="#faqExample"
                >
                  <div className="card-body">
                    <b>{t("faq.answer")}:</b> {t("faq.3.a")}
                  </div>
                </div>
              </div>
              {/* <div className="card">
                <div className="card-header p-2" id="headingThree">
                  <h5 className="mb-0">
                    <button
                      className="btn btn-link collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      {t("faq.question")}: {t("faq.4.q")}
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseFour"
                  className="collapse"
                  aria-labelledby="headingThree"
                  data-parent="#faqExample"
                >
                  <div className="card-body">
                    <b>{t("faq.answer")}:</b> {t("faq.4.a")}
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </ComponentWow>
  );
};

export default AccordionFAQ;
